export const globeConfig = {
  pointSize: 1,
  globeColor: "#062056",
  showAtmosphere: true,
  atmosphereColor: "#ffffff",
  atmosphereAltitude: 0.1,
  emissive: "#000000",
  emissiveIntensity: 0.1,
  shininess: 0.9,
  polygonColor: "rgba(255,255,255,0.7)",
  ambientLight: "#000000",
  directionalLeftLight: "#000000",
  directionalTopLight: "#ffffff",
  pointLight: "#ffffff",
  flightTime: 1000,
  flightLength: 0.9,
  rings: 1,
  maxRings: 3,
  initialPosition: { lat: 22.3193, lng: 114.1694 },
  autoRotate: true,
  autoRotateSpeed: 0.5,
};

export const flights = [
  {
    order: 1,
    from: "Raleigh",
    to: "Chicago",
    startLat: 35.787743,
    startLng: -78.644257,
    endLat: 41.739685,
    endLng: -87.554420,
    arcAlt: 0.2,
    color: "#FFFFFF",
  },
  {
    order: 1,
    from: "Tabasco",
    to: "Eyam",
    startLat: 18.400614,
    startLng: -93.212715,
    endLat: 53.283,
    endLng: -1.667,
    arcAlt: 0.5,
    color: "#FFFFFF",
  },
  {
    order: 2,
    from: "London",
    to: "Albania",
    startLat: 51.509865,
    startLng: 51.509865,
    endLat: 41.156986,
    endLng: 20.181221,
    arcAlt: 0.2,
    color: "#FFFFFF",
  },
  {
    order: 2,
    from: "Brooklyn",
    to: "Winchester",
    startLat: 40.650002,
    startLng: -73.94999,
    endLat: 51.063202,
    endLng: -1.308000,
    arcAlt: 0.3,
    color: "#FFFFFF",
  },
  {
    order: 3,
    from: "Raleigh",
    to: "Winchester",
    startLat: 35.7877438,
    startLng: -78.644257,
    endLat: 51.063202,
    endLng: -1.308000,
    arcAlt: 0.3,
    color: "#FFFFFF",
  },
  {
    order: 4,
    from: "Chicago",
    to: "Eyam",
    startLat: 41.739685,
    startLng: -87.554420,
    endLat: 53.2833,
    endLng: -1.667,
    arcAlt: 0.7,
    color: "#FFFFFF",
  },
  {
    order: 4,
    from: "Tabasco",
    to: "Brooklyn",
    startLat: 18.400614,
    startLng: -93.212715,
    endLat: 40.650002,
    endLng: -73.94999,
    arcAlt: 0.1,
    color: "#FFFFFF",
  },
  {
    order: 5,
    from: "Winchester",
    to: "Chicago",
    startLat: 51.063202,
    startLng: -1.308000,
    endLat: 41.739685,
    endLng: -87.554420,
    arcAlt: 0.3,
    color: "#FFFFFF",
  },
  {
    order: 5,
    from: "Eyam",
    to: "Tabasco",
    startLat: 53.283,
    startLng: -1.667,
    endLat: 18.400614,
    endLng: -93.212715,
    arcAlt: 0.2,
    color: "#FFFFFF",
  },
  {
    order: 6,
    from: "Chicago",
    to: "Eyam",
    startLat: 41.739685,
    startLng: -87.554420,
    endLat: 53.283,
    endLng: -1.667,
    arcAlt: 0.7,
    color: "#FFFFFF",
  },
  {
    order: 6,
    from: "Winchester",
    to: "Tabasco",
    startLat: 51.063202,
    startLng: -1.308000,
    endLat: 18.400614,
    endLng: -93.212715,
    arcAlt: 0.1,
    color: "#FFFFFF",
  },
  {
    order: 6,
    from: "Brooklyn",
    to: "Raleigh",
    startLat: 40.650002,
    startLng: -73.94999,
    endLat: 35.787743,
    endLng: -78.644257,
    arcAlt: 0.3,
    color: "#FFFFFF",
  },
  {
    order: 7,
    from: "London",
    to: "Winchester",
    startLat: 51.509865,
    startLng: 51.509865,
    endLat: 51.063202,
    endLng: -1.308000,
    arcAlt: 0.1,
    color: "#FFFFFF",
  },
  {
    order: 8,
    from: "Eyam",
    to: "London",
    startLat: 53.283,
    startLng: -1.667,
    endLat: 51.509865,
    endLng: -0.118092,
    arcAlt: 0.2,
    color: "#FFFFFF",
  },
  {
    order: 8,
    from: "Raleigh",
    to: "Tabasco",
    startLat: 35.787743,
    startLng: -78.644257,
    endLat: 18.400614,
    endLng: -93.212715,
    arcAlt: 0.5,
    color: "#FFFFFF",
  },
  {
    order: 9,
    from: "Albania",
    to: "London",
    startLat: 41.156986,
    startLng: 20.181221,
    endLat: 51.509865,
    endLng: -0.118092,
    arcAlt: 0.5,
    color: "#FFFFFF",
  },
  {
    order: 10,
    from: "Eyam",
    to: "Albania",
    startLat: 53.283,
    startLng: -1.667,
    endLat: 41.156986,
    endLng: 20.181221,
    arcAlt: 0.3,
    color: "#FFFFFF",
  },
  {
    order: 11,
    from: "Brooklyn",
    to: "Albania",
    startLat: 40.650002,
    startLng: -73.94999,
    endLat: 41.156986,
    endLng: 20.181221,
    arcAlt: 0.2,
    color: "#FFFFFF",
  },
  {
    order: 11,
    from: "Tabasco",
    to: "Chicago",
    startLat: 18.400614,
    startLng: -93.212715,
    endLat: 41.739685,
    endLng: -87.554420,
    arcAlt: 0.2,
    color: "#FFFFFF",
  },
];
